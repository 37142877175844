export const faqs = [
  {
    question:
      "Do I need a formal medical diagnosis before completing the Disability Tax Credit application?",
    answer:
      "No, you do not need a formal medical diagnosis to complete the Disability Tax Credit Application on our website, but we recommend that you talk to your medical practitioner about your condition so you can have all the relevant information you need prior to starting your application. Gathering all your medical information together to support your claim can take some time to obtain from hospitals, medical clinics, and specialists."
  },
  {
    question:
      "What if I currently do not have a family doctor who can sign the Disability Tax Credit application?",
    answer:
      "We can assist with resources to recommend a medical practitioner that can review and potentially sign your application. If you need assistance, please contact us at support@benefits2.ca. Please understand that medical practitioners will require all relevant medical documentation to support your impairment so please make sure you have all the documentation prepared and on hand once we connect you with a medical practitioner."
  },
  {
    question:
      "My family doctor will not sign the Disability Tax Credit application. What options do I have available to me now?",
    answer:
      "We can connect you with medical practitioner that can review and potentially sign your application. If you need assistance, please contact us at support@benefits2.ca. Please note that you must provide medical documentation to support your impairment such as x-rays, MRIs, etc."
  },
  {
    question:
      "My family doctor does not agree with some information indicated in the Disability Tax Credit application and would like to make some changes. How does my family doctor make those changes to the application?",
    answer:
      "When you create/ download your DTC Application on our website you are provided a “code” that is emailed to you that you can bring to your appointment with your medical practitioner. During sign-up of your account on our website you are asked for your mobile number and when you provide this “code” to the medical practitioner you will be sent a second security secure code (2-Factor Authentication) to your mobile phone which the medical doctor can then enter on our website to proceed to access your application and then edit information and later print off and sign. The medical practitioner can access your application by visiting our website www.dtc.benefits2.ca and choosing the “Physician Access” button in the top right corner of our website."
  },
  {
    question:
      "CRA has approved my Disability Tax Credit application and now I need assistance with my tax adjustments. Are you able to provide some guidance or assistance on what I need to do next?",
    answer:
      "We can put you in touch with companies that can review and potentially assist with adjusting your tax credits. If you need assistance, please contact us at support@benefits2.ca. Additionally, you can contact tax specialist companies or accounting firms in your local area to assist with tax adjustments."
  },
  {
    question:
      "I am interested in obtaining RDSP's are you able to provide information or assistance in this area?",
    answer:
      "If you do not have an advisor who can assist in opening an RDSP for you, please contact us at support@benefits2.ca and we will be happy to provide you with resources to find an advisor who specializes in RDSP’s. We recommend speaking with financial advisors who have experience working with RDSP’s with other clients and understand financial planning in this area."
  },
  {
    question:
      "CRA has denied my Disability Tax Credit application. Are you able to provide some guidance or assistance on what are my next steps?",
    answer:
      "We have a close relationship with legal professionals who are experienced in handling applications that have been denied. They will review your situation and discuss how they can help you move forward. Please contact us at support@benefits2.ca  if you would like to be placed in contact with them."
  },
  {
    question:
      "I want to use your website to apply for the Disability Tax Credit application, but I am nervous about using your website and not knowing which answers to correctly choose to complete the application?",
    answer:
      "In the event that you are uncertain about how to answer questions and feel that you need guidance, we offer a “live” assistant to help you through the application. During this Zoom video call or phone call, your dedicated assistant will answer all your questions and take you through the process from start to finish. This call is limited to 60 minutes and is $250 + Tax. To make the best use of your time, please have pages 1 and 2 of your application completed as best as you can. "
  },
  {
    question:
      "I have been previously denied by CRA after applying for Disability Tax Credit application? Can I use your website to apply again or what do you recommend are my next steps?",
    answer:
      "We can provide resources to have someone in the legal profession reach out to you directly to review your situation with CRA and learn more about how they can help you move forward. If you need assistance, please contact us at support@benefits2.ca. Please understand that when CRA denies a Disability Tax Credit application, the process to appeal the application could entail more detailed supporting documentation, a detailed understanding of your condition, and an in-depth review of your previous application."
  },
  {
    question:
      "I hear there are other companies out there that can assist me in getting the Disability Tax Credit. Can you tell me why I would use your website versus another company?",
    answer:
      "Our primary goal for creating Benefits2 is to make the process of applying for the Disability Tax Credit much easier and affordable for anyone in Canada to apply. Our application wizard provides easy to understand questions so people can answer questions confidently and in a way that makes sense without complex medical jargon that can sometimes be confusing and misleading. We have created a process that users applying can confidently complete the application and submit to CRA with a 98% success rate that the application is filled out correctly. In addition, our fee is a flat rate of $99.00 + Tax with no additional hidden fees."
  },
  {
    question:
      "What if I am using your website but I do not know some of the answers and must find out more information? Will your website “Save” my progress, so I am able to come back and complete the application later?",
    answer:
      "Yes, our website application wizard will save your progress for up to 30 days from time of purchase. The application must be completed within the 30-day period as the application will be deleted from our system after 30 days and the fee is non-refundable."
  },
  {
    question:
      "My medical practitioner has signed my Disability Tax Credit Application; how do I send the form to CRA?",
    answer: `Log in to your CRA account and …

    1.  Click on Submit Documents.
    2.  Click “Start”.
    3.  Select “No”.
    4.  Under “Topics” Select “Benefits and Credits”.
    5.  Select “Send Form T2201, Disability Tax Credit Application, or send supporting documents”.
    6.  Click “Next”.
    7.  Click “Upload file(s)” and then “Browse” then attach your application or supporting documents that you saved to your computer.
    8.  In the description box enter your name and DTC application i.e. “Jane Doe DTC application” and click “Attach file(s)”.
    9.  Click “Next”.
    10. Review to make sure you have the proper attachment.
    11. Click “Submit”.
    12. Print the confirmation page.`
  }
]
