import {
  Box,
  Typography,
  Stack,
  TextField,
  styled,
  TextFieldProps
} from "@mui/material"
import React, {ChangeEvent, useState} from "react"
import {IQuestion} from "models/IQuestion"

interface NumberTextBoxesProps {
  question: IQuestion
  answer: string
  onSelectAnswer: (answer: string) => void
}

interface GroupSectionProps {
  title: string
  answer: string
  onSelectAnswer: (answer: string) => void
}

const CustomButton = styled(TextField)<TextFieldProps>(({theme}) => ({
  input: {
    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      display: "none"
    }
  }
}))

const GroupSection: React.FC<GroupSectionProps> = (
  props: GroupSectionProps
) => {
  const {title, answer, onSelectAnswer} = props
  const [value, setValue] = useState<string>(answer)

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
  }

  const b = () => {
    onSelectAnswer(value)
  }

  return (
    <Box>
      <Typography
        fontSize={"16px"}
        fontWeight="600"
        variant="h6"
        gutterBottom
        component="div"
      >
        {title}
      </Typography>
      <Stack spacing={2}>
        <CustomButton
          id={`GroupSection-outlined-number-${title
            .trim()
            .replaceAll(" ", "")}`}
          type="number"
          InputLabelProps={{
            shrink: true
          }}
          onChange={handleChange}
          onBlur={() => b()}
          value={value}
        />
      </Stack>
    </Box>
  )
}

export const NumberTextBoxes: React.FC<NumberTextBoxesProps> = (
  props: NumberTextBoxesProps
) => {
  const {question, answer, onSelectAnswer} = props

  const onAnswerClicked = (index: number) => (newAnswer: string) => {
    const ansArray = answer.split(";")

    ansArray[index] = newAnswer

    console.log("ansArray", ansArray.join(";"))
    onSelectAnswer(ansArray.join(";"))
  }

  const getAnswer = (index: number) => {
    return answer.split(";")[index] || ""
  }

  return (
    <Box>
      <Box marginBottom="20px">
        <Typography
          fontSize={"28px"}
          fontWeight="600"
          variant="h6"
          gutterBottom
          component="div"
        >
          {question.text}
        </Typography>
        <Stack spacing={2}>
          {question.answerOptions.map((ans, i) => (
            <GroupSection
              key={`answerOptions_${i}`}
              title={ans}
              answer={getAnswer(i)}
              onSelectAnswer={onAnswerClicked(i)}
            />
          ))}
        </Stack>
      </Box>
    </Box>
  )
}
