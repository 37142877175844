import {AppButton} from "@app-core/AppButton"
import {useAuth} from "auth/AuthContext"
import {
  Alert,
  Autocomplete,
  Box,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  TextField,
  Typography
} from "@mui/material"
import {StripeTextFieldCVC} from "components/StripeTextFields/StripeTextFieldCVC"
import CircularProgress from "@mui/material/CircularProgress"
import {StripeTextFieldExpiry} from "components/StripeTextFields/StripeTextFieldExpiry"
import {CardNumberElement} from "@stripe/react-stripe-js"
import {useStripe, useElements} from "@stripe/react-stripe-js"
import {StripeTextFieldNumber} from "components/StripeTextFields/StripeTextFieldNumber"
import React, {useState} from "react"
import {applicationApi, paymentApi} from "data/api"
import {useNavigate} from "react-router-dom"
import {canadaProvinces} from "data/constants/canadaProvinces"
import {useEligibilityApplication} from "hooks/useEligibilityApplication"
import {stripeDataObjectConverter} from "utils/stripeHelper"

interface BillingDetailState {
  email: string
  nameOnCard: string
  postalCode: string
  city: string
  line1: string
  line2: string
  country: {name: string; code: string} | null
  state: string
  currency: string
}
interface CardDetailState {
  cardNumberComplete: boolean
  expiredComplete: boolean
  cvcComplete: boolean
  cardNumberError: null
  expiredError: null
  cvcError: null
}
export const CheckoutForm: React.FC = () => {
  const stripe = useStripe()
  const elements = useElements()
  const {eligibilityApplication} = useEligibilityApplication()
  const {user} = useAuth()
  const [errorMessage, setErrorMessage] = useState({
    open: false,
    message: ""
  })
  const [isLoading, setIsLoading] = useState(false)
  const [cardDetail, setCardDetail] = useState<CardDetailState>({
    cardNumberComplete: false,
    expiredComplete: false,
    cvcComplete: false,
    cardNumberError: null,
    expiredError: null,
    cvcError: null
  })
  const [billingDetail, setBillingDetail] = useState<BillingDetailState>({
    email: user?.email || "",
    nameOnCard: "",
    postalCode: "",
    city: "",
    line1: "",
    line2: "",
    country: {name: "Canada", code: "CA"},
    state: "",
    currency: ""
  })
  const navigate = useNavigate()

  const {cardNumberError, expiredError, cvcError} = cardDetail
  const [checked, setChecked] = useState<boolean>(false)
  const [promoCode, setPromoCode] = useState<{
    code: string
    verified: boolean
    discount: number
  }>({
    code: "",
    verified: false,
    discount: 0
  })

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked)
  }

  const onBillingDetailChange =
    (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setBillingDetail({
        ...billingDetail,
        [field]: event.target.value
      })
    }
  const onElementChange =
    (field: string, errorField: string) =>
    ({complete = false, error = {message: ""}}) => {
      setCardDetail({
        ...cardDetail,
        [field]: complete,
        [errorField]: error.message
      })
    }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSubmit = async (e: any) => {
    e.preventDefault()
    if (!elements || !stripe) {
      return
    }

    setIsLoading(true)

    try {
      const clientSecret = await paymentApi.createPaymentIntent({
        plan: eligibilityApplication?.plan || "",
        receipt_email: billingDetail.email,
        state: billingDetail.state,
        promoCode: promoCode.code || ""
      })
      const cardElement = elements.getElement(CardNumberElement)

      if (!cardElement) {
        return
      }

      const stripeDataObject = stripeDataObjectConverter(
        billingDetail,
        cardElement
      )
      const {paymentIntent, error} = await stripe.confirmCardPayment(
        clientSecret,
        stripeDataObject
      )

      if (error) {
        setErrorMessage({
          open: true,
          message: error.message || "Something went wrong. Please try again."
        })
      } else if (paymentIntent && paymentIntent.status === "succeeded") {
        const results = await applicationApi.startDtcApplication({
          paymentIntentId: paymentIntent.id,
          plan: eligibilityApplication?.plan || "",
          selectedSections: eligibilityApplication?.diagnoses || [],
          createdBy: user?.id || ""
        })

        navigate(`/payment-completion?clientCode=${results.applicationCode}`)
      } else if (
        paymentIntent &&
        paymentIntent.status === "requires_payment_method"
      ) {
        setErrorMessage({
          open: true,
          message: "Your payment was not successful, please try again."
        })
      }
      setIsLoading(false)
    } catch {
      setErrorMessage({
        open: true,
        message: "Something went wrong. Please try again."
      })
      setIsLoading(false)
    }
  }

  const getAmount = () => {
    if (eligibilityApplication?.plan === "DtcApp") {
      return (99.0 - 99.0 * promoCode.discount).toFixed(2).toString()
    } else if (eligibilityApplication?.plan === "DtcAppWithSupport") {
      return (79.95 - 79.95 * promoCode.discount).toFixed(2).toString()
    } else {
      setErrorMessage({
        open: true,
        message:
          "Can not find selected plan. Please hit the back button to select a plan."
      })
      return "Error"
    }
  }

  const isPayNowDisabled = () => {
    if (isLoading || errorMessage.open) {
      return true
    }

    if (!checked) {
      return true
    }

    if (stripe && elements && !errorMessage.open) {
      return false
    } else {
      return true
    }
  }
  // if (loading) return <LoadingScreen isLoading={loading} />

  const onVerifyPromoCode = async () => {
    try {
      if (promoCode.code) {
        const verifiedCodeDiscount = await paymentApi.verifyPromoCode({
          code: promoCode.code
        })

        if (verifiedCodeDiscount) {
          setPromoCode({
            ...promoCode,
            verified: true,
            discount: verifiedCodeDiscount
          })
          setErrorMessage({
            open: false,
            message: ""
          })
        } else {
          setErrorMessage({
            open: true,
            message: "Promo code you entered doesn't exist"
          })
        }
      }
    } catch (error) {
      console.log("onVerifyPromoCode error", error)
      setErrorMessage({
        open: true,
        message: "Promo code you entered doesn't exist"
      })
    }
  }

  const onPromoCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPromoCode({
      ...promoCode,
      code: event.target.value
    })
  }
  return (
    <Container maxWidth="md">
      <form id="payment-form" onSubmit={handleSubmit}>
        <Box padding={4}>
          <Box
            display={"flex"}
            alignItems="center"
            justifyContent={"center"}
            flexDirection="column"
          >
            <Box
              display={"flex"}
              alignItems="center"
              justifyContent={"center"}
              flexDirection="column"
              width="100%"
              height="75px"
              sx={{background: "#EDEDED", borderRadius: "21px"}}
            >
              <Typography
                color="#6A75B9"
                fontSize="28px"
                variant="h6"
                component="div"
              >
                <Typography
                  color="#191919"
                  fontSize="28px"
                  variant="h6"
                  component="span"
                >
                  Amount:
                </Typography>{" "}
                {`$${getAmount()}`}
              </Typography>
              <Typography
                color="#191919"
                fontSize="12px"
                variant="h6"
                component="span"
              >
                {` (Plus taxes based on selected province)`}
              </Typography>
            </Box>
            <Box marginTop="20px" marginBottom="20px">
              {errorMessage.open && (
                <Alert
                  variant="filled"
                  severity="error"
                  onClose={() => setErrorMessage({open: false, message: ""})}
                >
                  {errorMessage.message}
                </Alert>
              )}
              <Typography
                fontSize="28px"
                variant="h6"
                gutterBottom
                component="div"
              >
                Please enter your method of payment
              </Typography>
            </Box>
          </Box>
          <Box
            display={"flex"}
            alignItems="center"
            justifyContent={"center"}
            flexDirection="column"
          >
            {/* <PaymentElement id="payment-element" /> */}
            <Box maxWidth={"sm"}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    name="nameOnCard"
                    required
                    fullWidth
                    id="nameOnCard"
                    label="Name on Card"
                    value={billingDetail.nameOnCard}
                    onChange={onBillingDetailChange("nameOnCard")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <StripeTextFieldNumber
                    error={Boolean(cardNumberError)}
                    labelErrorMessage={cardNumberError || ""}
                    onChange={onElementChange(
                      "cardNumberComplete",
                      "cardNumberError"
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6} xl={6}>
                  <StripeTextFieldExpiry
                    error={Boolean(expiredError)}
                    labelErrorMessage={expiredError || ""}
                    onChange={onElementChange(
                      "expiredComplete",
                      "expiredError"
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6} xl={6}>
                  <StripeTextFieldCVC
                    error={Boolean(cvcError)}
                    labelErrorMessage={cvcError || ""}
                    onChange={onElementChange("cvcComplete", "cvcError")}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6} xl={6}>
                  <TextField
                    name="line1"
                    required
                    fullWidth
                    id="line1"
                    label="Street Address 1"
                    value={billingDetail.line1}
                    onChange={onBillingDetailChange("line1")}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6} xl={6}>
                  <TextField
                    name="line2"
                    fullWidth
                    id="line2"
                    label="Street Address 2 (optional)"
                    value={billingDetail.line2}
                    onChange={onBillingDetailChange("line2")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Country"
                    name="country"
                    variant="outlined"
                    required
                    fullWidth
                    value={billingDetail.country?.name}
                    disabled
                  />
                  {/* <Autocomplete
                    fullWidth
                    options={countries}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        label="Country"
                        name="country"
                        variant="outlined"
                        required
                        {...params}
                      />
                    )}
                    value={billingDetail.country}
                    onChange={(event, value) => {
                      setBillingDetail({
                        ...billingDetail,
                        country: value
                      })
                    }}
                  /> */}
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    fullWidth
                    options={canadaProvinces}
                    openOnFocus={false}
                    renderInput={(params) => (
                      <TextField
                        label="Province/State"
                        name="state"
                        variant="outlined"
                        required
                        {...params}
                      />
                    )}
                    value={billingDetail.state}
                    onChange={(event, value) => {
                      setBillingDetail({
                        ...billingDetail,
                        state: value || ""
                      })
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="city"
                    required
                    fullWidth
                    id="city"
                    label="City"
                    value={billingDetail.city}
                    onChange={onBillingDetailChange("city")}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    name="postalCode"
                    required
                    fullWidth
                    id="postalCode"
                    label="Postal Code"
                    value={billingDetail.postalCode}
                    onChange={onBillingDetailChange("postalCode")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box>
                    {promoCode.verified && (
                      <Typography color={"green"}>
                        Promo code discount has been applied to total amount
                      </Typography>
                    )}
                    {!promoCode.verified && (
                      <>
                        <TextField
                          sx={{marginRight: "12px", flexGrow: ".3"}}
                          name="promoCode"
                          id="promoCode"
                          label="Promo Code"
                          value={promoCode.code}
                          onChange={onPromoCodeChange}
                        />
                        <AppButton
                          sx={{width: "80px", marginTop: "10px"}}
                          color="primary"
                          variant="contained"
                          onClick={onVerifyPromoCode}
                        >
                          Apply
                        </AppButton>
                      </>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box marginTop="20px" marginBottom="20px">
              <FormControlLabel
                label={
                  <Typography
                    fontSize="14px"
                    variant="body1"
                    gutterBottom
                    component="div"
                    color="#707070"
                  >
                    I hereby agree to complete payment process using credit card
                    to Benefits2 account
                  </Typography>
                }
                control={
                  <Checkbox checked={checked} onChange={handleCheckboxChange} />
                }
              />
            </Box>
            <Box marginTop="20px">
              <AppButton
                sx={{backgroundColor: "#6A75B9", width: "160px"}}
                variant="contained"
                disabled={isPayNowDisabled()}
                type="submit"
                // onClick={(e) => onSubmit(e)}
              >
                {isLoading ? (
                  <CircularProgress color="inherit" size={30} />
                ) : (
                  "Pay Now"
                )}
              </AppButton>
            </Box>
          </Box>
        </Box>
      </form>
    </Container>
  )
}
